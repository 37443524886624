let SMPNum = require ("@/SMPNum");

class CharactersKpiUtils {
    constructor (heroesData, supportersData, petsData, multipleSessionKpiUtils, supportKpiUtils) {
        this.heroesData = heroesData;
        this.supportersData = supportersData;
        this.petsData = petsData;
        this.multipleSessionKpiUtils = multipleSessionKpiUtils;
        this.supportKpiUtils = supportKpiUtils;
    }

    /*================HERO================*/
    getHeroUnlockedCount(zoneIndex){
        let heroUnlockCount = zoneIndex + 1;
        if(heroUnlockCount > this.heroesData.length){
            heroUnlockCount = this.heroesData.length;
        }
        return heroUnlockCount;
    }

    isHeroUnlockYet(heroId, zoneIndex){
        let count = this.getHeroUnlockedCount(zoneIndex);
        let unlock = 0;
        let isUnlock = false;
        this.heroesData.forEach(p => {
            if(p.m_iID === heroId){
                isUnlock = unlock < count;
                return;
            }
            unlock++;
        });
        return isUnlock;
    }

    getDisplayHeroList(count) {
        let heroes = [];
        let unlock = 0;
        this.heroesData.forEach(p => {
            p.heroIsUnlock = unlock < count;
            p.levelUnlock = unlock === 0 ? 1 : unlock * 10;
            p.possibleLevel = p.heroIsUnlock ? p.levelUnlock : 0;
            unlock++;
            heroes.push(p);
        });

        return heroes;
    }

    getHeroDataById(heroId){
        return this.heroesData.find(hero => hero.m_iID === heroId);
    }
    /*================End HERO================*/

    /*================PET======================*/
    getPetDataById(petID){
        return this.petsData.find(pet => pet.petID === petID);
    }
    /*================END PET==================*/

    getTeamsBattleData(bossInfo, heroUnlockCount, gameLevel, kpiHeroList, supportsHaveUnlock){
        //default best hero
        let bestHeroUnlock = kpiHeroList[0];//this.heroesData[0];
        let bossElement = bossInfo.elementType;
        let heroElement = bestHeroUnlock.elementType;
        let gainNumber = this.multipleSessionKpiUtils.getForwardGainNumber(heroElement, bossElement);
        let bestPercent = this.multipleSessionKpiUtils.getPercentByGainNumber(gainNumber);
        let bestHeroUnlocks = [];

        //to get best hero with element type
        for(let index = 0;index < heroUnlockCount;index++){
            heroElement = kpiHeroList[index].elementType;//this.heroesData[index].elementType;
            gainNumber = this.multipleSessionKpiUtils.getForwardGainNumber(heroElement, bossElement);
            let percent = this.multipleSessionKpiUtils.getPercentByGainNumber(gainNumber);

            if(percent > 0)
            {
                bestHeroUnlocks.push({
                hero: kpiHeroList[index],
                bonus: percent
            });
            }

            if(percent > bestPercent
                || (percent >= bestPercent && bestHeroUnlock.possibleLevel < kpiHeroList[index].possibleLevel)){
                bestPercent = percent;
                bestHeroUnlock = kpiHeroList[index];//this.heroesData[index];
            }
        }

        //to get best hero with element type vs support type
        //console.log('support list ',supportsHaveUnlock);
        if(bestHeroUnlocks.length > 1 && supportsHaveUnlock !== undefined)
        {
            ////default to sort by descending
            bestHeroUnlocks = bestHeroUnlocks.filter(b => b.hero !== bestHeroUnlock).sort((a,b) => a.bonus < b.bonus ? 1 : -1);
            heroElement = bestHeroUnlock.elementType;
            // let supportsHaveUnlock = this.supportKpiUtils.getPossibleSupportUnlocked(gameLevel, 0);
            // console.log('unlk support', supportsHaveUnlock);
            let bestHireSupports = this.getBestSupportTeam(heroElement, supportsHaveUnlock, false);
            let bestHpBonus = this.getHeroHpWillBonusBySupport(bestHeroUnlock.possibleLevel, heroElement, bestHireSupports, supportsHaveUnlock, false);
            for(let index = 0; index < bestHeroUnlocks.length; index++)
            {
                let bestHero = bestHeroUnlocks[index].hero;
                heroElement = bestHero.elementType;
                bestHireSupports = this.getBestSupportTeam(heroElement, supportsHaveUnlock, false);
                let hpWillBonus = this.getHeroHpWillBonusBySupport(bestHero.possibleLevel, heroElement, bestHireSupports, supportsHaveUnlock, false);
                if(SMPNum.greaterThan(hpWillBonus, bestHpBonus))
                {
                    bestHeroUnlock = bestHero;
                    break;
                }
            }
        }

        //default best pet
        let petsUnlock = this.petsData.filter(pet => pet.levelUnlock <= gameLevel);
        let bestPetUnlock = petsUnlock.length > 0 ? petsUnlock[0] : undefined;
        heroElement = bestHeroUnlock.elementType;
        for(let index = 0;index < petsUnlock.length;index++){
            //console.log('pet: '+petsUnlock[index].petID+' element: '+petsUnlock[index].elementType+" bestForHero: "+this.multipleSessionKpiUtils.getReverseStepElement(heroElement));
            if(petsUnlock[index].elementType === this.multipleSessionKpiUtils.getReverseStepElement(heroElement)){
                bestPetUnlock = petsUnlock[index];
                //console.log('set best pet '+bestPetUnlock.petID);
                break;
            }
        }
        // if(bestPetUnlock){
        //     console.log('best pet '+bestPetUnlock.petID);
        // }

        return {
            heroData: bestHeroUnlock,
            //supportsData: supportsData,
            petData: bestPetUnlock
        };
    }

    getHeroHpWillBonusBySupport(heroLevel, heroElement, bestSupport, supportsHaveUnlock, isLimitHeroLevel){
        let supportElements = [];
        let supportUnlocked = [];
        bestSupport.forEach(supportData =>{
            if(!supportElements.includes(supportData.elementType)){
                supportElements.push(supportData.elementType);
            }

            let support = supportsHaveUnlock.find(s => s.m_iID === supportData.m_iID);
            if(support){
                supportUnlocked.push(support);
            } else {
                console.log('why support here is null in m_iID: '+supportData.m_iID);
            }
        });

        let hpResult = this.multipleSessionKpiUtils.getHeroHpSessionResultFromSupports(heroLevel, heroElement, supportElements, supportsHaveUnlock, isLimitHeroLevel);
        return hpResult.bonusHp;
    }

    getBestSupportTeam(heroElement, supportsHaveUnlock, isUseFlyingSupport){
        /*
        SUPPORT1 = 0,//RED
        HERO, //sword
        LEAF, //leaft
        SUPPORT3,//BLUE
        SUPPORT4,//orange
        SUPPORT5,//PURPLE
        EMPTY
         */
        let colorSelects = [0, 3, 4, 5];
        let supportsByColors = [];
        colorSelects.forEach(color => {
            supportsByColors.push({
                color: color,
                supports: [],
                flySupports: [],
            });
        });

        //bestSupportsElements
        let bestSupportsElements = [];
        let gainElement = this.multipleSessionKpiUtils.getReverseStepElement(heroElement);
        bestSupportsElements.push(gainElement);
        for(let i =1 ;i <= 3 ; i++){
            gainElement = this.multipleSessionKpiUtils.getReverseStepElement(gainElement);
            bestSupportsElements.push(gainElement);
        }

        let bestSupports = [];
        supportsHaveUnlock.forEach(supportUlk => {
            let support = this.supportersData.find(s => s.m_iID === supportUlk.m_iID);
            let sColor = supportsByColors.find(sc => sc.color === support.m_iFruitType);
            if(support.m_SupportStandType === 2){//it flying support
                if(isUseFlyingSupport){
                    sColor.flySupports.push(support);
                }
            } else {
                sColor.supports.push(support);
            }
        });

        supportsByColors.forEach(sColor =>{
            if(sColor.supports.length > 0){
                let bstSupport = this.GetBestSupportInList(sColor.supports, bestSupportsElements, bestSupportsElements[0])
                bestSupports.push(bstSupport);
                bestSupportsElements = bestSupportsElements.filter(c => c !== bstSupport.elementType);
            }
        });

        supportsByColors.forEach(sColor =>{
            sColor.flySupports.forEach(support => {
                bestSupports.push(support);
            });
        });

        return bestSupports;
    }

    GetBestSupportInList(supportsByColor, bestSupportsElements, bestColor){
        if(supportsByColor.length === 1){
            return supportsByColor[0];
        } else {
            let bestSupport = supportsByColor[0];
            for(let i = 1;i<supportsByColor.length;i++){
                if(supportsByColor[i].elementType === bestColor || (bestSupportsElements.includes(supportsByColor[i].elementType) && bestSupport.elementType !== bestColor)){
                    bestSupport = supportsByColor[i];
                }
            }

            return bestSupport;
        }
    }
}

export default CharactersKpiUtils;