<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col>
                <GroupControl
                    :inputConfigSet="inputConfigSet"
                    :pService="service"
                    @onKpiUpdate="onKpiUpdate"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <GroupInputLevel
                    :defaultInputSet="inputConfigSet"
                    :payload="kpiResultSet"
                    :pService="service"

                    @onUpdateInput="onUpdateInput"
                    @onCapture="onCapture"
                    @onClearCaptures="onClearCaptures"
                />
            </v-col>
            <v-col>
                <PanelMarketItemRequire
                        :payload="kpiResultSet"
                        :gameLevel="inputConfigSet.gameLevel"
                />
            </v-col>
            <GroupTab :payload="kpiResultSet">
                <template v-slot:tabGamePlayKpi>
                    <GroupTabGameplayKpi
                        :payload="kpiResultSet"
                    />
                </template>
                <template v-slot:tabHeroes>
                    <GroupTabHeroes
                        :payload="kpiResultSet"
                    />
                </template>
                <template v-slot:tabSupport>
                    <GroupTabSupport
                        :payload="kpiResultSet"
                        :pService="service"
                    />
                </template>
                <template v-slot:tabPet>
                    <GroupTabPet
                        :payload="kpiResultSet"
                    />
                </template>
                <template v-slot:tabMarketPlace>
                    <GroupTabMarketPlace
                            :payload="kpiResultSet"
                    />
                </template>
            </GroupTab>
        </v-row>
    </v-container>
</template>

<script>
import GroupControl from "@/kpi-editor/modules/components/GroupControl.vue";
import GroupInputLevel from "@/kpi-editor/modules/components/GroupInputLevel.vue";
import GroupTabGameplayKpi from "@/kpi-editor/modules/components/GroupTabGameplayKpi.vue";
import GroupTab from "@/kpi-editor/modules/components/GroupTab.vue";
import GroupTabSupport from "./GroupTabSupport.vue";
import GroupTabHeroes from "./GroupTabHeroes.vue";
import GroupTabPet from "./GroupTabPet.vue";
import GroupTabMarketPlace from "./GroupTabMarketPlace.vue";
import LocalStorageUtils from "@/kpi-editor/Utils/LocalStorageUtils";
import PanelMarketItemRequire from './PanelMarketItemRequire';
import CaptureKpiUtils from "../../Utils/CaptureKpiUtils";
import LogicSimulationService from "@/kpi-editor/services/LogicSimulationService";
import BalanceKpiUtils from "@/kpi-editor/Utils/BalanceKpiUtils";
import SupportKpiUtils from "@/kpi-editor/Utils/SupportKpiUtils";
import PetKpiUtils from "@/kpi-editor/Utils/PetKpiUtils";
import HeroSkillKpiUtils from "@/kpi-editor/Utils/HeroSkillKpiUtils";
import EnemyKpiUtils from "../../Utils/EnemyKpiUtils";
import CharactersKpiUtils from "../../Utils/CharactersKpiUtils";
import SupportSkillsConstant from "./../../../constants/SupportSkillsConstants";

export default {
    name: "KpiEditorViewUsingMVC",
    components: {
        GroupTabPet,
        GroupTabMarketPlace,
        PanelMarketItemRequire,
        GroupTabHeroes, GroupTabSupport, GroupTab, GroupTabGameplayKpi, GroupInputLevel, GroupControl},
    data () {
        return {
            kpiResultSet:{},

            inputConfigSet:{
                gameLevel: 1,
                heroLevel: 1,
                ratioAllocatedToSupport: 50,
                ratioAllocatedToSupportLevel: 77,
                ratioSupportSkill: 23,

                ratioAllocatedToPet: 50,
                ratioAllocatedToHeroSkill: 50,

                percentageGoldBonus: 0,
                percentControlPetGoldSkill: 50,
                percentControlSupportGoldSkill: 50,

                isUsePetSkillGold: true,
                isUsePetSkillDmg: true,
                isUseSupportSkillGold: true,
                isUseSupportSkillDmg: true,
                isUsingPet: true,

                timeRequireForDmgItem: 20,
                timeRequireForHpItem: 10,

                isIncludeMiniQuestGold: true,
                isIncludeDailyQuestGold: true,
                percentIncludeMiniQuestGold: 70,
                percentIncludeDailyQuestGold: 100,

                isUseHeroSkillGold: true,
                isUseHeroSkillDmg: true,
                percentControlHeroGoldSkill: 50,
                percentControlHeroDmgSkill: 50,
                isUseThunderAttackSkill: true,
                isUsePetAllianceSkill: true,

                isUseAllGoldForMarketPlace: false,

                balHeroLevelBelowBoss: 5,
                balSupportLevelBelowBoss: 3,
            },

            captureKpiUtils: undefined,
            service: {},
        }
    },
    methods: {
        onKpiUpdate (payload){
            this.kpiResultSet = payload;
        },

        onUpdateInput(payload) {
            this.inputConfigSet = payload;

            //save to local storage
            LocalStorageUtils.saveObject("inputConfigSet", payload);
        },
        onCapture(){
            this.captureKpiUtils.captureKpiResultSet(this.kpiResultSet);
        },
        onClearCaptures(){
            this.captureKpiUtils.clearKpiCaptures();
        },
        initLogicSimulatorService(){
            let gamePlayDataService = this.$store.state.gamePlayDataService;
            let balanceKpiUtils = new BalanceKpiUtils(gamePlayDataService);
            let supportKpiUtils = new SupportKpiUtils(this.$store.state.skillList, gamePlayDataService, SupportSkillsConstant);

            let petsData = this.$store.state.petsData;//this.$store.state.petList

            let petKpiUtils = new PetKpiUtils(petsData, gamePlayDataService);
            let heroSkillKpiUtils = new HeroSkillKpiUtils(this.$store.state.heroSkillList, gamePlayDataService);
            let balanceBuilder = this.$store.state.balanceBuilder;

            let enemysData = this.$store.state.enemysData;
            let enemysZoneData = this.$store.state.enemysZoneData;
            let enemyKpiUtils = new EnemyKpiUtils(enemysData, enemysZoneData);

            let heroesData = this.$store.state.heroesData;
            let supportersData = this.$store.state.supportersData;
            let multipleSessionKpiUtils = gamePlayDataService.multipleSessionKpiUtils;//new MultipleSessionKpiUtils(this.$store.state.multipleSessionConfig, this.$store.state.gamePlayDataService);

            let charactersKpiUtils = new CharactersKpiUtils(heroesData, supportersData, petsData, multipleSessionKpiUtils, supportKpiUtils);

            this.service = new LogicSimulationService(
                balanceKpiUtils,
                gamePlayDataService,
                supportKpiUtils,
                petKpiUtils,
                heroSkillKpiUtils,
                balanceBuilder,
                enemyKpiUtils,
                charactersKpiUtils,
                multipleSessionKpiUtils
            );
        },
    },
    created() {
        const inputConfigSet = LocalStorageUtils.getObject("inputConfigSet");
        if (inputConfigSet){
            this.inputConfigSet = inputConfigSet;

            if(!this.inputConfigSet.timeRequireForDmgItem){
                this.inputConfigSet.timeRequireForDmgItem = 20;
                this.inputConfigSet.timeRequireForHpItem = 10;
            }

            if(this.inputConfigSet.isIncludeMiniQuestGold === undefined){
                this.inputConfigSet.isIncludeMiniQuestGold = true;
                this.inputConfigSet.isIncludeDailyQuestGold = true;
            }

            if(this.inputConfigSet.percentIncludeMiniQuestGold === undefined){
                this.inputConfigSet.percentIncludeMiniQuestGold = 70;
                this.inputConfigSet.percentIncludeDailyQuestGold = 100;
            }

            if(this.inputConfigSet.percentControlHeroDmgSkill === undefined){
                this.inputConfigSet.percentControlHeroDmgSkill = 50;
                this.inputConfigSet.percentControlHeroGoldSkill = 50;
                this.inputConfigSet.isUseHeroSkillDmg = true;
                this.inputConfigSet.isUseHeroSkillGold = true;
                this.inputConfigSet.isUseThunderAttackSkill = true;
                this.inputConfigSet.isUsePetAllianceSkill = true;
            }

            if(this.inputConfigSet.isUseAllGoldForMarketPlace === undefined){
                this.inputConfigSet.isUseAllGoldForMarketPlace = false;
            }

            if(this.inputConfigSet.balHeroLevelBelowBoss === undefined){
                this.inputConfigSet.balHeroLevelBelowBoss = 5;
                this.inputConfigSet.balSupportLevelBelowBoss = 3;
            }
        }

        this.initLogicSimulatorService();

        this.captureKpiUtils = new CaptureKpiUtils(LocalStorageUtils);
    }
}
</script>

<style scoped>

</style>